/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

.ProductCustomizableOptions {

    &-Content {

        .Field_type_checkbox {
            label {
                padding-left: 3rem;
                .input-control {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1.7rem;
                    height: 1.7rem;
                }
            }

            input {
                width: auto;
            }
        } 

        @include desktop {
            max-width: unset;
        }
    }

    &-ExpandableContentHeading {

        @include desktop {
            font-weight: bold !important;
            border-bottom: 1px solid #d3d3d3;
            padding-bottom: 2rem;
            font-size: 1.6rem;
        }
    }

    &-HeadingBold {
        color: var(--body-heading-color);
    }
}
