/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

.Menu {
    &-MenuWrapper {
        @include desktop {
            top: var(--header-nav-height);
            z-index: 1;
        }
    }

    &-MainCategories {
        @include desktop {
            padding-right: 1.2rem;
        }
        @include tablet {
            // margin-top: 60px;
        }
    }

    &-ItemList {
        @include desktop {
            margin-right: 0;
            width: 100%;
            display: flex;
            border-top: 1px solid var(--strong-divider-color);
            border-bottom: 1px solid var(--strong-divider-color);
            justify-content: left;
        }

        .isHomePage & {
            @include desktop {
                border-top: 1px solid var(--color-white);
                border-bottom: 1px solid var(--color-white);
            }
        }

    }

    &-Item {

        @include tablet {
            padding: 0 .8rem;
        }

        .lightning {
            figcaption {
                &:before {
                    @include desktop {
                        content: "";
                        background-image: url(icons/lightning.png);
                        width: 11px;
                        height: 17px;
                        background-size: 11px 17px;
                        display: inline-block;
                        margin-right: .5rem;
                        vertical-align: bottom;
                        background-repeat: no-repeat;
                    }
                }
            }
        }

        .black-week, .january-sale, .vinter-sale {
            figure {
                padding: 0 10px;
                margin-top: 9px;
                border-radius: 60px;
                height: 50px;
                padding-left: 30px;

                @include desktop {
                    height: auto;
                    padding-left: 10px;
                }

                figcaption {
                    line-height: 30px;
                    font-size: 13px;
                    font-style: italic;
                    font-weight: 700;
                }
            }

        }
        .black-week {
            figure {
                background-color: black;
                box-shadow: 0px 0px 8px 0px $pink;
            }
            figcaption {
                color: $pink !important;
            }
        }
        .january-sale {
            figure {
                background-color: $orange;
            }
            figcaption {
                color: $white !important;
            }
        }
        .vinter-sale {
            figure {
                background-color: $purple;
            }
            figcaption {
                color: $white !important;
            }
        }

        .isHomePage & {

            &:hover {
                @include desktop {
                    background-color: var(--color-white);
                    transition: .3s ease-in all;
                }

                figcaption {
                    color: var(--primary-base-color);
                    transition: .3s ease-in all;
                }

            }
        }

    }

    &-ItemFigure {
        @include desktop {
            height: auto;
            padding: 1.5rem 0;
        }
    }

    &-ItemCaption {
        font-weight: 600;
        color: var(--body-heading-color);

        &_type_main {
            .isHomePage & {
                @include desktop {
                    color: var(--color-white);
                }
            }
        }

        @include before-desktop {
            font-size: var(--h5-font-size);
            text-transform: none;
        }

    }

    &-CompareLinkWrapper {
        @include before-desktop {
            display: none;
        }
    }

    &-PageLink {
        line-height: 22px;
        @include before-desktop {
            font-size: var(--h5-font-size);
        }

        .Menu-Link {
            img {
                vertical-align: top;
            }
        }

    }

    &-SubCategoriesWrapper {
        @include desktop {
            top: 50px;
        }

        .isHomePage & {
            top: 48px;
        }

    }

    &-SubCategories {
        @include desktop {
            padding: 30px 1.2rem;
        }
    }

    &-ItemList {
        &_type_subcategory {
            @include desktop {
                border: 0;
                justify-content: flex-start;
            }
        }
    }

    &-SubLevelDesktop {
        .Menu-Link {
            @include desktop {
                margin: 5px 0;
            }

            &:before {
                @include desktop {
                    content: ">";
                }
            }
        }

        .Menu-ItemList {
            border-top: 0;
            border-bottom: 0;
        }

        .Menu-ItemFigure {
            @include desktop {
                padding: .5rem;
                display: inline-block;
            }
        }
    }

    &-Promotion {
        justify-content: center;
        padding: 30px 1.2rem 30px!important;
        border-top: 1px solid var(--secondary-base-color);

        h3 {


            &:not(:first-child) {
                @include desktop {
                    margin-top: 0;

                }
            }

            &:last-child {
                @include desktop {
                    margin-right: 0;
                }
            }
        }

        img {
            width: 35px;
            max-width: 35px;

        }
    }

    &-Overlay {
        &_isVisible {
            @include desktop {
                top: 0;
            }
        }
    }

    &-SubItemWrapper {
        @include desktop {
            flex-basis: 25%;
            margin-right: 0;
            padding: 0 2rem;
        }

        .Menu-ItemFigure {
            @include desktop {
                padding-bottom: 0;
            }
        }
    }

    &-Social {
        display: none!important;
    }

}
