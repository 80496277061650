.cms-image-text-block {
    margin-bottom: 85px;

    .Image.Image_ratio_square {
        @include desktop {
            height: 45rem;
        }
    }

    .cms-row {
        padding-bottom: 1rem;
    }

    img {
        display: block;
        margin-bottom: var(--cms-spacing-x2);

        @include desktop {
            max-width: 75%;
            margin-bottom: 0;
        }
    }

    .cms-button {
        @include mobile {
            display: block;
            text-align: center;
        }
    }

    &__content {
        background-color: #fff;   
        position: relative;
        box-shadow: 0px 0px 21px 0px rgba(51, 51, 51, 0.15);
        padding: 2rem;

        @include desktop {
            margin-top: -330px;
            max-width: 49%;
            padding: 6rem;
            margin-left: auto;
        }

        h2 {
            font-weight: 800;
            margin-bottom: 2rem;
            margin-top: 0;
            text-transform: uppercase;
            letter-spacing: -0.02em;
            
            @include desktop {
                line-height: 3.5rem;
            }
            
            .subtitle {
                font-size: 3.5rem;
                color: var(--body-heading-color);
                font-weight: 300;
                line-height: normal;
                text-transform: none;

                &__bold {
                    font-weight: 400;
                }

            }
        }

        &__introtitle {
            font-size: 1.4rem;
            color: var(--body-content-color);
            margin-bottom: 1rem;
            text-transform: uppercase;

            &:before {
                content: "";
                width: 2.3rem;
                height: 2px;
                color: var(--color-pink);
                background-color: var(--color-pink);
                display: inline-block;
                margin-right: 2rem;
                vertical-align: middle;
            }

        }

        &__subtitle {
            font-size: 3.5rem;
            color: var(--body-heading-color);
            font-weight: 400;
            line-height: normal;
        }

        .cms-button.cms-button-secondary {
            margin-top: 2rem;
            text-transform: uppercase;
            font-size: 1em;
            font-weight: 700;
        }

    }

    &--revert {
        img {    
            @include desktop {
                margin-left: auto;
            }
        }

        .cms-image-text-block__content {
            @include desktop {
                margin-left: 0;
                padding: 6rem;
            }
        }

    }

    .cms-link-pink {
        color: var(--color-pink);
        font-size: 1.5rem;
        font-weight: 500;
        margin-top: 2rem;
        display: inline-block;

        &:before {
            content: "";
            background-image: url('../icons/link_pil.svg');
            width: 23px;
            height: 12px;
            background-size: 23px 12px;
            display: inline-block;
            margin-right: 1.5rem;
            vertical-align: center;

            @include desktop {
                margin-right: 2rem;
            }
        }

        &:hover {
            text-decoration: none;
        }
    }

    ul {
        padding-left: 0;
        list-style-type: square;
        margin-top: 2rem!important;

        li {
            font-size: 1.3rem;
            display: list-item;
            list-style-type: square;
            padding-left: 0;
            &:before {
                display: none;
            }
        }

    }

}

.cms-featured-brands-block {
    
    &-content {
        height: 90px;
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-bottom-right-radius: 3rem;
        border-top-left-radius: 3rem;
        margin-bottom: 2rem;
    }

    
    &-container {
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        font-size: var(--h4-font-size);
        color: #fff;
        text-align: center;
        z-index: 20;
        font-weight: 700;
        padding: .5rem 0;

        @include mobile {
            text-align: left;
        }
    }


}

