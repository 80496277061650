.full-width-container {
    padding-top: var(--section-spacing);
    padding-bottom: var(--section-spacing);
    margin-bottom: var(--section-spacing);

    &--blue {
        background-color: #f2f5f9;

        .slick-list {
            &:after {
                background-color: #f2f5f9!important;
            }
        }

    }

}