.cms-text-block {
    &__content {
        background-color: #fff;   
        position: relative;

        @include desktop {
            padding: var(--cms-spacing-x3);
            margin: auto;
            text-align: center;
            padding-bottom: 0;
        }

        &--width-75 {
            
            @include desktop {
                max-width: 60%;
            }

        }

        .cms-row > .cms-col {
            padding: 0 var(--cms-spacing-x2);
        }

        h2 {
            font-weight: 800;
            margin-bottom: 2rem;
            margin-top: 0;
            text-transform: uppercase;
            letter-spacing: -0.02em;
            
            @include desktop {
                line-height: 3.5rem;
            }
            
            .subtitle {
                font-weight: 500;
                font-size: var(--h4-font-size);

                @include before-desktop {
                    display: block;
                }

            }
        }

        h4 {
            margin-top: 1em;
        }

        &__introtitle {
            font-size: var(--h6-font-size);
            color: var(--body-heading-color);
            font-weight: 700;
            margin-bottom: 1rem;
            text-transform: uppercase;
        }

        &__subtitle {
            font-size: var(--h3-font-size);
            color: var(--body-heading-color);
            line-height: normal;
            text-transform: uppercase;
        }

        .cms-button.cms-button-secondary {
            margin-top: 2rem;
            text-transform: uppercase;
            font-size: 1em;
            font-weight: 700;
        }
    }
    &.qualities-block {
        .cms-col {
            @include before-desktop {
                margin-bottom: var(--cms-spacing-x2);
            }
            &:last-child {
                @include before-desktop {
                    margin-bottom: 0;
                } 
            }
        }
    }
}