.cms-featured-cats-block {
    h2 {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: var(--cms-spacing-x3)
    }

    &__cat {
        display: block;
        height: 170px;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            left: 0; right: 0;
            top: 0; bottom: 0;
            background: rgba(0, 0, 0, 0);
            z-index: 10;
        }

        // @include desktop {
        //     &--vertical {
        //         height: 500px;
        //     }
        // }
    }

    &__cat-content {
        display: block;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: .5rem;

        &:before {
            content: "";
            position: absolute;
            left: 0; 
            right: 0;
            top: 0; 
            bottom: 0;
            background: rgba(0,0,0,.2);
            border-radius: .5rem;
        }

        // @include desktop {
        //     &--left {
        //             border-bottom-left-radius: 3rem;
        //     }

        //     &--right {
        //         border-bottom-right-radius: 3rem;
        //     }
        // }

    }

    &__cat-container {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        display: block;
        width: 100%;
        font-size: var(--h4-font-size);
        color: #fff;
        text-align: left;
        z-index: 20;
        font-weight: 700;
        padding: .5rem 0;
    }

    .cms-col-3 {
        @include tablet-custom {
            width: 50%;
        }
    }

}