/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

.ProductCard {
    margin: 2rem 0;
    box-shadow: 3px 3px 9px -5px rgba(0,0,0,0.5);
    padding: 10px 10px 5px 10px;
    .ProductCard-Picture{
      transition: transform .5s ease;
      transform: scale(1.00);
    }
    &:hover {
      border: 1px solid #26323f;
      .ProductCard-Picture {
        transition: transform .5s ease;
        transform: scale(1.05);
      }
    }

    @include desktop {
        margin: 2rem;
    }

    &-OnSaleTag,
    &-OnSalePercent {
        text-transform: uppercase;
        position: absolute;
        z-index: 9;
        font-weight: 700;
        border-radius: 60px;
        background: $orange;
        color: $white;
        padding: 6px 10px;
    }
    &-OnSaleTag {
        left: 6px;
        top: 6px;
        font-size: 12px;
        font-style: italic;
    }
    &-OnSalePercent {
        top: 38px;
        left: 6px;
        font-size: 14px;
    }

    &-Brand {
        text-transform: uppercase;
    }

    &-Name {
        overflow: unset;
        white-space: unset;
        text-overflow: unset;
        color: var(--body-heading-color);
    }

    &-Picture {
        padding-bottom: 60%;
    }

    &-PriceBadge {
        color: inherit;
    }

    &-Stock {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        padding-top: .5rem;

        .ProductCardStockStatus {
            &-StockIcon {
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                background-color: rgb(62, 172, 79);
                margin-right: 1rem;
            }
        }

        &_outOfStock {
            .ProductCardStockStatus {
                &-StockIcon {
                    background-color: var(--color-pink);
                }
            }
        }
    }

    .ProductPrice {

        @include desktop {
            font-size: 1.8rem;
            display: flex;
        }

        &-HighPrice {
            order: 2;
            color: var(--body-content-color);
            display: block;
            padding: .5rem 0;

            @include desktop {
                font-size: 1.8rem;
                display: inline-block;
                padding: 0;
            }

            &::before {
                @include desktop {
                    content: "/ ";
                }
            }
        }
    }

    &-DiscountSplat {
        position: absolute;
        top: 3%;
        left: 0;
        z-index: 5;
        font-size: 1.4rem;
        margin-top: 8px;

        span {
            background-color: var(--color-pink);
            color: var(--black);
            padding: 5px;

            @media (min-width: 1200px) {
                padding: 7px !important;
            }
            font-weight: 600;


            span {
                background-color: var(--color-pink);
                color: var(--secondary-base-color);
                padding: 5px 10px 5px 5px;
                margin-left: 5px;
            }
        }
    }

    &-PriceRunnerSplat {
        position: absolute;
        bottom: 0;
        right: 3%;
        z-index: 5;

        img {
            vertical-align: middle;
        }
    }

    .ProductWishlistButton {

        .Button {
            border: none;
        }

        &-Heart {
            --wishlist-heart-size: 20px;


        }

        &:hover {

            .ProductWishlistButton-Heart {
                @include after-mobile {
                    background-color: var(--color-pink);
                }
            }

            ::before,
            ::after {
                @include after-mobile {
                    background-color: var(--color-pink);
                }
            }
        }
    }
}
