/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

:root {
    --menu-height: 60px;
    @include desktop {
        --header-background: #f8f8f8;
        --menu-height: 57px;
        --header-nav-height: 105px;
        --header-top-menu-height: 65px;
        --header-height: calc(var(--header-nav-height) + var(--header-top-menu-height) + var(--menu-height));
    }
}
body { // to be moved to style folder
    @include before-desktop {
        --header-height: 120px;
    }
}
.Header {
    @include desktop {
        height: var(--header-height);
    }

    @include desktop {
        position: fixed;
        border-bottom: 0;
    }

    @include before-desktop {
        &_name_default {
            height: var(--header-height);

            .Header-Nav {
                display: block;

                &>*:not(.Header-HrSearch) {
                    display: none;
                }
            }
        }
    }

    .isHomePage & {
        @include desktop {
            --header-background: transparent;
            position: fixed;
        }

        &_scrolled {
            @include desktop {
                --header-background: #f8f8f8;
            }

            .Header {
                &-LogoWrapper {

                    @include desktop {
                        background-image: url('images/logo_pos.svg');
                    }
                }

                &-Button {
                    &_type_account {
                        @include desktop {
                            background-image: url(icons/profil_neg.svg);
                        }
                    }
                }

                &-MinicartIcon {
                    @include desktop {
                        background-image: url(icons/kurv_neg.svg);
                    }
                }

                &-StoreIcon {
                    @include desktop {
                        background-image: url(icons/vaerksted_neg.svg);
                    }
                }

                &-MinicartTitle {
                    @include desktop {
                        color: var(--header-color)
                    }
                }

                &-MyAccountTitle {
                    @include desktop {
                        color: var(--header-color)
                    }
                }

                &-StoreTitle {
                    @include desktop {
                        color: var(--header-color)
                    }
                }
            }
        }
    }

    &-Wrapper {
        @include desktop {
            margin-bottom: 225px;
        }

        &_name_checkout {
            --header-height: 0;
        }

        .isHomePage & {
            @include desktop {
                margin-bottom: 0;
            }
        }

        .isCheckoutPage & {
            @include desktop {
                margin-bottom: 0;
            }
        }
    }

    &_isCheckout {
        // @include desktop {
            --header-height: 0;
            display: none;
        // }
    }

    &-TopMenu {
        background-color: var(--secondary-base-color);
        max-width: 100%;
        padding: 0;
    }

    &-Contacts {
        display: block;
        width: 100%;
        overflow: hidden;

        @include desktop {
            overflow: visible;
        }

        .CmsBlock-Wrapper {
            display: block;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &-LogoWrapper {
        height: 45px;

        @include desktop {
            margin: 0;
            left: 1.2rem;
            height: 80px;
            top: 50%;
            transform: translateY(-50%);
        }

        .isHomePage & {
            background-image: url('images/logo_neg.svg');
            width: 170px;
            height: 80px;
            background-size: 170px 80px;

            .Image {
                background: transparent!important;
            }

        }

        .Image-Image {
            object-fit: contain;
            @include desktop {
                padding: 1rem 0;
                height: auto;
                width: 170px;
                padding-top: 30px;
            }

            .isHomePage & {
                @include desktop {
                    display: none;
                }
            }

        }

    }

    &-MyAccountTitle {
        display: none;

        @include desktop {
            display: block;
            padding-right: 0;
            margin-top: .2rem;
            font-size: 1.5rem;
            font-weight: 500;
            text-transform: none;
        }

        .isHomePage & {
            @include desktop {
                color: var(--color-white);
            }
        }

    }

    &-MinicartTitle {
        display: none;

        @include desktop {
            display: block;
            padding-right: 0;
            margin-top: .2rem;
            font-size: 1.5rem;
            font-weight: 500;
            text-transform: none;
            margin-right: 0;
        }

        .isHomePage & {
            @include desktop {
                color: var(--color-white);
            }
        }
    }

    &-MyAccountWrapper {
        @include desktop {
            display: flex;
            flex-direction: column-reverse;
            margin-right: 2.16rem;
        }
    }

    // &-CompareButtonWrapper {
    //     border-right: 0;
    //     margin-right: 0;
    //     padding-right: 0;
    // }

    .MyAccountOverlay {
        @include desktop {
            top: calc(var(--header-nav-height) - 30px);
            &:before {
                right: 65px;
            }
        }
    }

    .CartOverlay {
        @include desktop {
            top: calc(var(--header-nav-height) - 30px);
            &:before {
                background-color: var(--overlay-background);
            }
        }
        &-Promo {
            @include desktop {
                display: none;
            }
        }
        &-Empty {
            @include desktop {
                padding: 2rem;
            }
        }
    }

    .SearchField  {
        &-SearchWrapper {
            top: var(--header-total-height);
        }
    }


    &-Title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &_isVisible {
            @include before-desktop {
                margin-top: auto!important;
                max-width: calc(100% - 100px);
            }
        }
    }

    &-Button {
        &_type_share {
            @include before-desktop {
                display: none;
            }
        }

        &_type_back {
            @include before-desktop {
                margin-left: -7.5px;
            }
        }

        &_type_minicart {
            @include desktop {
                padding: 0;
            }
        }
        &_type_account {
            @include desktop {
                &:after {
                    display: none;
                }
                &:before {
                    display: none;
                }
                background-size: contain;
                width: 20px;
                height: 20px;
                background-image: url(icons/profil_neg.svg);
                // margin-right: 2rem;
            }

            .isHomePage & {
                @include desktop {
                    background-image: url(icons/profil.svg);
                }
            }

        }
    }

    &-MinicartButtonWrapper {
        @include desktop {
            display: flex;
            flex-direction: column-reverse;
            height: auto;
        }
    }

    &-MinicartIcon {
        &:before {
            display: none;
        }
        &:after {
            display: none;
        }


        @include desktop {
            padding: 0;
            background-size: contain;
            width: 20px;
            height: 20px;
            background-image: url(icons/kurv_neg.svg);

            .isHomePage & {
                @include desktop {
                    background-image: url(icons/kurv.svg);
                }
            }
        }

    }

    &-MinicartItemCount {
        top: -5px;
        left: 18px;
        transform: none;

        .isHomePage & {
            @include desktop {
                background-color: #fff;
                color: black;
                font-weight: bold;
            }
        }

    }

    .CartOverlay {
        @include desktop {
            right: 18px;
        }
    }

    &-HrSearch {
        position: relative;
        width: 100%;

        &_mobileActive {
            display: block;
        }

        @include desktop {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 0;
            width: auto;
            display: block;
        }

        &-Input {
            border-radius: 20px;
            padding: 1rem 5.5rem 1rem 3rem;

            width: 100%;
            @include desktop {
                min-width: 500px;
            }

            @include tablet {
                min-width: 300px;
            }

            &:active,
            &:focus {
                border: 1px solid var(--input-border-color);
            }

        }

        &-Icon {
            width: 20px;
            height: 20px;
            position: absolute;
            right: 2rem;
            top: 50%;
            transform: translateY(-50%);
            background-image: url(icons/search.svg);
            background-size: contain;
        }

    }


    &-StoreIcon {
        @include desktop {
            padding: 0;
            background-size: contain;
            width: 20px;
            height: 20px;
            background-image: url(icons/vaerksted_neg.svg);
        }

        .isHomePage & {
            @include desktop {
                background-image: url(icons/vaerksted.svg);
            }
        }
    }

    &-StoreTitle {
        display: none;

        @include desktop {
            display: block;
            padding-right: 0;
            margin-top: .2rem;
            font-size: 1.5rem;
            font-weight: 500;
            text-transform: none;
        }

        .isHomePage & {
            @include desktop {
                color: var(--color-white);
            }
        }

        &:hover {
            text-decoration: none;
        }
    }

    &-StoreBtn {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

        &:hover {
            text-decoration: none;
        }

    }

    &_scrolled {
        @include desktop {
            height: 75px;
            transition: .2s ease-in all;
            box-shadow: 0 6px 10px rgba(195, 195, 195, .4);
        }
        .Menu {
            &-MenuWrapper {
                @include desktop {
                    transition: .2s ease-in all;
                    opacity: 0;
                    height: 0;
                }
            }
        }

        .Header {
            &-Nav {
                @include desktop {
                    transition: .2s ease-in all;
                    height: 70px;
                }
            }

            &-TopMenu {
                @include desktop {
                    transition: .2s ease-in all;
                    height: 0;
                    opacity: 0;
                }
            }

            &-MinicartItemCount {
                .isHomePage & {
                    background-color: black;
                    color: white;
                    font-weight: bold;
                }

            }

        }

    }

}
