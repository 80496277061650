:root{
    --option-text-color: var(--body-content-color);
}

.CategoryMenuItem {
    
    &-Link {
        color: var(--option-text-color);
        line-height: 1.7rem;
        font-size: 1.4rem;
        text-transform: none;
    }
}
