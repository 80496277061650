.product-trust-widget {
    display: flex;
    justify-content: center;

    @include desktop {
        padding-top: 2rem;
        border-top: 1px solid #d3d3d3;
    }

    .Checkout-TrustWidgetContainer & {
        padding-top: 0;
        border-top: 0;
    }

    &__element {
        
        text-align: center;
        margin-bottom: 1rem;
        margin-top: 1rem;
        width: 33%;

        .Checkout-TrustWidgetContainer & {
            display: grid;
            grid-template-columns: 15px auto;
            grid-gap: 8px;
            text-align: left;

            @include mobile {
                display: block;
                text-align: center;
            }

            .Image {
                max-width: 15px;
            }

        }

        @include desktop {
            display: flex;
            align-items: center;
            margin-right: 2rem;
            text-align: left;
            width: unset;
            
        }

        &:last-child {
            margin-right: 0;

           @include tablet {
                display: none;
            }
        }

        .Image {
            max-width: 35px;
            margin: 0.5rem auto 0;

            @include desktop {
                margin: 0 .8rem 0 0;
            }
        }

        p {
            margin-bottom: 0;
            font-size: 1.2rem;
            font-weight: 500;

            @include desktop {
                font-size: 1.2rem;
            }
        }

        a {
            color: var(--body-content-color);
        }

    }
}