.large-hero {

    --body-heading-color: var(--color-white);
    --h1-font-size: 3.5rem;

    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 400px;
    margin-bottom: var(--cms-spacing-x3);

    @include desktop {
        --h1-font-size: 6rem;
        height: 790px;
        // margin-top: var(--header-top-menu-height);
    }

    &__content {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        max-width: 600px;

        @include desktop {
            top: 60%;
        }
        
        &__subtitle {
            color: var(--color-white);
            font-size: 1.5rem;
            font-weight: 500;
            margin-bottom: 5rem;
        }

        &__title {
            margin-bottom: 5rem;
            text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
            font-weight: 400;

            &__bold {
                font-weight: 700;
                text-shadow: 2px 10px 10px rgb(0, 25, 51);
                
            }
        }

        &__btn {
            display: inline-block;
            padding: 1.5rem 2rem;
            background-color: var(--color-pink);
            color: var(--color-white);
            font-size: 1.5rem;
            font-weight: 600;

            &:hover {
                background-color: #DC0059;
                border-color: #DC0059;
                text-decoration: none;
            }

            @include desktop {
                padding: 2rem 3rem;
            }

            .arrow {

                &:hover {
                    padding-left: 0.5rem;
    
                    &:before {
                        @include desktop {
                            margin-right: 2.5rem;                            
                        }
                    }
                }

                &:before {
                    content: "";
                    background-image: url('../icons/arrow_btn.svg');
                    width: 23px;
                    height: 12px;
                    background-size: 23px 12px;
                    display: inline-block;
                    margin-right: 1.5rem;
                    vertical-align: center;

                    @include desktop {
                        margin-right: 3rem;
                    }

                }
            }

        }

    }


}