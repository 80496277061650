.footer-newsletter-section {
    padding: 5rem 0;
    border-top: 2px solid #f2f5f9;

    @include desktop {
        display: flex;
        padding: 5rem 70px;
    }

    .klaviyo {
        &-form {
            @include desktop {
                border: 1px solid #c7d9f0;
                border-radius: 5rem;
                padding: 0 10px;
                margin: 2rem 0;
            }

            .fuOFIe.fuOFIe {
                @include desktop {
                    display: none;
                }
            }
            
            .Row__FormRow-sc-178phst-1 {
                @include mobile {
                    display: block;
                }
            }

            .fBlUkM.fBlUkM {
                @include mobile {
                    padding-right: 0;
                }
            }

            .iNCOdd.iNCOdd {
                @include mobile {
                    padding-left: 0;
                }
            }

            input {

                @include mobile {
                    border-color: #c7d9f0 !important;
                    border-radius: 2rem !important;
                }

                @include desktop {
                    border: none !important;
                    margin-bottom: 5px !important;
                }
            }

            button { 
                padding-top: 16px !important;
                padding-bottom: 16px !important;

                &:hover {
                    padding-left: 0.5rem;

                    &:before {
                        @include desktop {
                            margin-right: 2.5rem;                            
                        }
                    }
                }

                &::before {
                    content: "";
                    background-image: url('../icons/arrow_btn.svg');
                    width: 23px;
                    height: 12px;
                    background-size: 23px 12px;
                    display: inline-block;
                    margin-right: 1.5rem;
                    vertical-align: center;

                    @include desktop {
                        margin-right: 3rem;
                       
                    }
                }
            }
        }
    }

    &__content {
        margin-bottom: 2rem;
        @include desktop {
            width: 50%;
            padding-right: 5rem;
        }

        h2 {
            text-align: left!important;
        }
    }

    &__form {
        @include desktop {
            width: 50%;
            padding-left: 10rem;
        }
        
        p {
            font-size: 1.3rem;
            margin-top: 2rem;

            @include desktop {
                text-align: right;
                margin-top: 1rem;
            }

        }

        .Row__FormRow-sc-17nghml-0  {
            @include mobile {
                display: block!important;
            }
        }

        .FormComponent__StyledFormComponentWrapper-e0xun6-0 {
            @include mobile {
                padding-right: 0!important;
            }
        }


    }

}