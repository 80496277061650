@keyframes slideeffect {
    0% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(-400px, 0, 0);
    }
}

.usp-block {
    position: relative;
    .black-friday-notification {
        background-color: $dark;
        &__item {
            a {
                color: $pink;
            }
        }
    }
    .january-sale-notification {
        background-color: $orange;
        &__item {
            a {
                color: $white;
            }
        }
    }

    .vinter-sale-notification {
        background-color: $purple;
        &__item {
            a {
                color: $white;
            }
        }
    }

    .black-friday-notification, .january-sale-notification, .vinter-sale-notification {
        display: block;
        overflow: hidden;
        width: 100%;
        margin-bottom: 0;
        top: -2px;

        &__list {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            max-width: var(--content-wrapper-width);
            margin: 0 auto;
            overflow: hidden;
        }

        &__item {
            display: none;
            margin: 0;
            padding: 0;
            animation: slideeffect 10s linear infinite;

            &:first-of-type {
                display: inline-block;
            }

            @include desktop {
                display: inline-block;
            }

            &::before {
                display: none;
            }

            a {
                display: block;
                white-space: nowrap;
                padding: 7.5px 20px;
                font-weight: 500;
                font-size: 14px;

                em {
                    font-weight: 900;
                    text-transform: uppercase;
                }
            }
        }
    }

    &__list {
        display: flex;
        justify-content: left;
        max-width: var(--content-wrapper-width);
        margin: 0 auto;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
        align-items: center;

        li {
            list-style-type: none;
            list-style: none;
            display: block;
            padding: 0;
            margin-right: 1rem;
            color: var(--body-heading-color);
            font-weight: 600;
            margin-bottom: 0;
            font-size: 1.2rem;



            a {
                color: var(--primary-divider-color);
                text-decoration: underline;
                font-weight: 500;
                font-size: 1.3rem;
                font-weight: 600;
            }

            &:last-child {
                margin-right: 0;
            }

            &:before {
                content: "";
                display: none;
            }


            &.contact {
                &:before {
                    display: inline-block;
                    position: relative;
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 25px;
                    height: 25px;
                    margin-right: 1rem;
                    padding-right: 0;
                    left: 0;
                    background-image: url("https://www.pedalatleten.dk/media/hoeks-pwa/icons/kundeservice.svg");
                    vertical-align: middle;
                }
            }

            &.trustpilot {


                &-Widget {
                    width: 289px;
                    margin-right: -70px;
                    position: absolute;
                    right: 0;
                    margin-top: 5px;

                    iframe:nth-child(2) {
                        right: 80px;
                    }
                }

                &-Img {
                    width: 80px;
                    position: absolute;
                    right: 140px;
                    margin-top: 3px;
                    height: auto !important;
                    object-fit: unset;
                    display: block;

                    .Image-Image::after {
                        background: none;
                    }
                }
            }

            &.emaerket {
                width: 100px;
                opacity: .5;
                filter: brightness(0) saturate(100%) invert(14%) sepia(77%) saturate(1311%) hue-rotate(187deg) brightness(93%) contrast(93%);
            }

            &.contact {
                opacity: .5;
                top: 1px;
            }

            &.separator {
                &:after {
                    content: "|";
                    padding-right: 1rem;
                    opacity: 0.5;
                }
            }
        }
    }
}
