/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

:root {
    --wishlist-heart-size: 12px;
    --wishlist-heart-background: #dcdcdc;
}

.ProductWishlistButton {

    .Button_isHollow {
        border-color: #c7d9f0;
    }
   
    &-Button {
        --button-hover-background: transparent;
        --button-hover-border: var(--color-pink);

        &_isInWishlist {
            --wishlist-heart-background: #fff;
            --button-hover-background: var(--color-pink);
            --button-hover-border: var(--color-pink);

            &:hover {
                @include after-mobile {
                    --wishlist-heart-background: #fff;
                }
            }
        }
    }

    &:hover {
        @include after-mobile {
            --wishlist-heart-background: var(--color-pink);
        }
    }

    &-Heart {
        &,
        &::after,
        &::before {
            background-color: var(--wishlist-heart-background);
        }
    }
}
