.trust-pilot-home {
    width: var(--cms-width);
    max-width: var(--cms-max-width);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: calc(var(--cms-vertical-padding-x2) * 2);
    margin-top: calc(var(--cms-vertical-padding-x2) * 2);

    h3 {
        font-weight: 700;
        margin-bottom: 2rem;
        margin-top: 0;
        text-transform: uppercase;
        letter-spacing: -0.02em;
        margin-bottom: var(--cms-spacing-x2);

        @include mobile {
            text-align: center;
        }

        @include desktop {
            margin-bottom: var(--cms-spacing-x3);
        }

    }
} 