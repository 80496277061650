.cms-usp-block {
    // background-color: var(--grey-cms-background);
    padding-bottom: calc(var(--cms-vertical-padding-x2) * 2 )!important;

    &__usp {
        text-align: center;
        max-width: 300px;
        margin: 0 auto;

        img {
            display: block;
            margin: 0 auto;
            margin-bottom: var(--cms-spacing-x1);
            max-width: 85px;

        }
    }

    &__title {
        color: var(--body-heading-color);

        &__bold {
            display: block;
            font-size: 1.5rem;
            font-weight: 900;

            &--inline {
                display: inline;
            }

        }

    }

    &__text {
        font-size: 1.3rem;

        @include mobile {
            display: none;
        }
    }

    .cms-col-3 {
        @include tablet-custom {
            width: 25%;
        }
    }

    .cms-col-4 {
        @include tablet-custom {
            width: 33.3%;
        }
    }

}