/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

.Footer {

    .Image {
        padding: 0;
        height: auto;
        display: block;
        width: 350px !important;

        .Image-Image {
            position: relative;
            height: auto !important;
            width: 100%;
            max-width: 100%;
            object-fit: unset;
            display: block;
        }
    }

    &-Content {
        background-color: #f2f5f9;
        border-bottom: 1px solid #dde3ea;
    }
    &-CopyrightContentWrapper {
        background-color: #fff;
    }

    &-Columns {
        flex-direction: column;

        .CmsBlock-Wrapper {
            @include desktop {
                display: flex;
            }
            
            &_type_ {
            @include tablet {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
            }}
        }

    }

    &-Column {
        padding-right: 0!important;
        margin-left: 2rem;
        margin-right: 2rem;

        .icon-footer {
            display: none!important;
            filter: invert(1);
            max-width: 22px;
            
            @include mobile {
                margin: 0 auto;
                display: block;

            }

            @include tablet {
                margin-left: -40px;
                margin-bottom: -42px;
            }

            @include desktop {
                margin-left: -40px;
                margin-bottom: -42px;
            }
        }

        @include tablet {
            max-width: 30%;
            width: 30%;
            display: inline-block;
            margin-left: 10%;
            margin-right: 0;
        }

        @include desktop {
            max-width: 25%;
            width: 25%;
        }

        &:not(:last-of-type) {
            max-width: unset;
        }

        .CmsBlock-Wrapper {
            display: flex;
        }

        ul {
            li {
                padding-left: 0;
                &:before {
                    display: none;
                }
            }
        }

        &_isNewsletter {
            text-align: center;
            width: 100%;
            max-width: 100%;
            padding-left: 0!important;
            margin: 0;
            margin-bottom: 2rem;
            margin-top: 2rem;

            @include tablet {
                margin-left: 0%;

            }


            .Footer-ColumnTitle {
                display: none;
            }

            .NewsletterSubscription {
                width: 100%;
                padding: 1rem;
                border: 1px solid #c7d9f0;
                border-radius: 5rem;

                @include mobile {
                    border: 0;
                    padding: 0;
                }

                .FieldForm {       
                    &-Fields {     
                        max-width: 100%;

                        @include desktop {
                            width: 300px;
                        }

                        input {
                            &::placeholder {
                                color: var(--var-body-content-color);
                            }
                        }

                    } 

                    &-Button {
                        background-color: #504e4e!important;
                        border-color: #504e4e!important;
                    }

                    .FieldForm-Fieldset {
                        width: 100%;

                        @include mobile {
                            display: block;
                        }

                        .FieldForm-Fields {
                            width: 55%;

                            @include mobile {
                                width: 100%;
                                margin-bottom: 2rem;
                            }
                            
                            input {
                                height: 47px;
                                border: 0;
                                color: rgb(158, 167, 180);
                                font-size: 15px;

                                @include mobile {
                                    border: 1px solid #c7d9f0;
                                    border-radius: 22px;
                                }
                            }

                            .Field-Message {
                                margin-top: 1.2rem;

                                @include mobile {
                                    margin-top: .1rem;
                                }

                            }

                        }

                        .FieldForm-Button {
                            height: 47px;
                            width: 45%;
                            border-radius: 22px;
                            background-color: rgb(235, 0, 94)!important;
                            border: 0;
                            font-weight: 400;
                            font-size: 16px;

                            @include mobile {
                                width: 100%;
                            }

                            &:after {
                                display: none;
                            }

                            &:before {
                                content: "";
                                background-image: url('../../style/cms-theme-specific/icons/arrow_btn.svg');
                                width: 23px;
                                height: 12px;
                                background-size: 23px 12px;
                                display: inline-block;
                                border: 0;
                                position: relative;
                                top: auto;
                                left: 0;
                                margin-right: 3rem;
                                transform: none;
                            }

                            &:hover {
                                padding-left: 29px;
                                &:before {
                                    margin-right: 2.5rem;
                                }
                            }

                        }

                    }

                }

                .FieldForm-Button {
                    background-color: var(--secondary-base-color);
                }

            }
        }

    }

    &-ColumnTitle {
        color: var(--body-heading-color);
        font-size: 1.3rem;
        font-weight: 900;
    }

    &-ColumnContent {
        * {
            color: var(--body-heading-color);
            font-size: 1.3rem;
        }

        a {
            &:hover {
                text-decoration: underline;
                // color: #fff;
            }
        }

        .klaviyo {
           @include desktop { 
                margin: 0 1rem 0 -1rem;
           }
        }

    }

}

