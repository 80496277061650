.footer-support {
    background-color: #f2f5f9;
    border-bottom: 1px solid #dde3ea;
    border-top: 1px solid #dde3ea;

    &__wrapper {
        padding: 60px 1.2rem; 
        max-width: var(--content-wrapper-width);
        margin-left: auto;
        margin-right: auto;
        display: block;

        @include desktop {
            display: flex;
            padding: 60px 90px; 
        }

    }

    &__el {
        &:first-child {
            margin-bottom: 2rem;

            @include desktop {
                margin-right: 100px;
                margin-bottom: 0;
            }

            p {
                &:before {
                    background-image: url('../icons/support.svg');
                }
            }
        }

        p {
            line-height: 30px;
            margin-bottom: 0;
            font-size: 1.8rem;
            color: var(--body-heading-color);
            &:before {
                content: "";
                background-image: url('../icons/email.svg');
                width: 30px;
                height: 30px;
                background-size: 30px 30px;
                display: inline-block;
                margin-right: 1.5rem;
                vertical-align: bottom;
            }
        }
    }

}