.slick-slider {
    @include before-desktop {
        margin: 0;
    }

    @include desktop {
        width: 100%;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
        max-width: var(--content-wrapper-width);
        margin-left: auto;
        margin-right: auto;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .slick-arrow {
        background: transparent;
        height: auto;
        width: auto;
        border-radius: 50%;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
        z-index: 1;
        transition: all ease .3s;

        &::before {
            font-weight: bold;
            content: '<';
            top: 0;
            left: 0;
            display: block;
            opacity: 1;
            font-size: 2rem;
            font-family: 'Courier New', Courier, monospace;
            color: #aeaeae;
            transform: scaleY(1.5);
        }

        &.slick-next {
            right: 3rem;

            @include before-desktop {
                right: 1rem;
                top: 35%;
            }

            &::before {
                transform: scaleY(1.5);
                content: ">";
            }
        }

        &.slick-prev {
            left: 3rem;


            @include before-desktop {
                left: 1rem;
                top: 35%;
            }
        }

        &:hover {
            // background: var(--button-hover-background);
        }
    }

    .slick-list {
        margin: 0;

        .slick-track {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            padding: 20px 0;

            .slick-slide {
                padding: 0;
                height: unset;

                > div {
                    height: 100%;
                }

                .SliderWidget-Figure {
                    display: block !important;
                    height: 100%;
                    max-height: 600px;

                    & > a {
                        display: block;
                        height: 100%;
                        width: 100%;
                    }

                    .SliderWidget-FigureImage {
                        height: 100%;
                        width: 100%;
                        padding-bottom: 30%;

                        @include tablet {
                            padding-bottom: 15%;
                        }

                        @include desktop {
                            padding-bottom: 0;
                        }

                        .Image-Image {
                            display: block;
                            height: 100%!important;
                            width: 100%!important;
                            object-fit: cover;
                            object-position: 50% 50%;
                        }
                    }

                    .SliderWidget-Figcaption {
                    //    display: none;
                    }
                }

                // fix for sliders with product images 
                .ProductCard {
                    .Image {
                        padding: 40% 0;
                        display: inline-block;
                        height: 0;

                        .Image-Image {
                            position: absolute;
                            object-fit: contain;
                            height: 100%!important;
                        }
                    }
                }
            }
        }
    }

    .slick-dots {
        display: none !important;
    }
}