.footer-copyright {
    background-color: #fff;
    padding: 5rem 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    max-width: var(--content-wrapper-width);
    margin-left: auto;
    margin-right: auto;

    @include desktop {
        flex-direction: row;
        padding: 5rem 70px;
    }

    & > div {
        @include desktop {
            margin: 0 var(--cms-spacing-x2);
        }

        &:first-child {
            margin-bottom: 2rem;
            @include desktop {
                margin-bottom: 0;
            }
        }

    }

    .footer-copy-right {
        &-right {
            width: 450px;
            max-width: 100%;
        }
    }


    .Image {
        padding: 0;
        height: auto;
        display: block;
        width: auto!important;

        .Image-Image {
            position: relative;
            height: auto !important;
            width: auto!important;
            object-fit: unset;
            display: block;
        }
    }

    p {
        @include desktop {
            margin-bottom: 0;
        }
    }



}