.CmsPage {
    &-Heading {
        .HomePage & {
            display: none;
        }
    }

    &-Wrapper {
        .HomePage & {
            margin-top: 0;
        }
    }

    .CmsPage-Content {
        h1 {
            font-size: var(--h1-font-size);
        }
        
        h2 {
            font-size: var(--h2-font-size);
        }
        
        h3 {
            font-size: var(--h3-font-size);
        }
        
        h4 {
            font-size: var(--h4-font-size);
        }
        
        h5 {
            font-size: var(--h5-font-size);
        }
        
        h6 {
            font-size: var(--h6-font-size);
        }
    }

    .isCmsPage:not(.isHomePage) & {
        .Image {
            padding: 0;
            height: auto;
            display: block;
            width: auto!important;
            margin-bottom: 2rem;
            margin-top: 2rem;
    
            .Image-Image {
                position: relative;
                height: auto !important;
                width: auto!important;
                object-fit: unset;
                display: block;
            }
        }
    }

}

body {
    &.isHomePage {
        overflow-x: hidden;
    }

    &.isProductPage {
        overflow-x: hidden;
    }

}