.cms-section {
    .Image {
        padding: 0;
        height: auto;
        display: block;

        .Image-Image {
            position: relative;
            height: auto !important;
            object-fit: unset;
            display: block;
        }

        &:not(.width-control) {
            width: 100% !important;
        }
    }

    &.standard-margin {
        margin-bottom: var(--section-spacing);
    }

    .general-title {
        text-align: center;
        font-size: 3.5rem;
        color: var(--body-heading-color);
        font-weight: 300;
        line-height: normal;
        text-transform: none;

        &__bold {
            font-weight: 700;
            &--block {
                @include desktop {
                    display: block;
                }
            }
        }

    }


}